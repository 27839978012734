/* eslint-disable import/no-unresolved */
<template>
  <section id="courses">
    <p>Gerenciamento de Materiais</p>
    <div>
      <add-new
        v-if="isAddNewSidebarActive"
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        @refresh="refresh()"
        @close="isAddNewSidebarActive = false"
      />

      <edit
        v-if="isEditSidebarActive"
        :is-edit-sidebar-active.sync="isEditSidebarActive"
        :data="rowData"
        @refresh="refresh()"
        @close="isEditSidebarActive = false"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Mostrar</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>aulas</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="9"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchValue"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewSidebarActive = true"
                >
                  <span class="text-nowrap">Adicionar aula</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="overflow-auto">
          <b-table
            id="mytable"
            ref="mytable"
            hover
            :items="items"
            :fields="tableColumns"
            :filter="searchValue"
            primary-key="id"
            :total-rows="rows"
            :per-page="perPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :current-page="currentPage"
            show-empty
            empty-text="Nenhuma informação encontrada"
            busy.sync="isBusy"
            empty-filtered-text="Nenhum registro filtrado"
          >
            <template slot="empty">
              <div
                v-if="isBusy"
                class="text-center"
              >
                <b-spinner class="align-middle" />
              </div>
              <div
                v-else
                class="text-center"
              >
                Nenhuma informação encontrada
              </div>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="edit(data.item)">
                  <feather-icon
                    icon="EditIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Editar</span>
                </b-dropdown-item>

                <b-dropdown-item @click="del(data.item.id)">
                  <feather-icon
                    icon="TrashIcon"
                  />
                  <span
                    class="align-middle ml-50"
                  >Deletar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <!-- <span class="text-muted">A partir de {{ dataMeta.from }} para {{ dataMeta.to }} de {{ dataMeta.of }} palestrantes</span> -->
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import AddNew from './AddNew.vue'
import Edit from './Edit.vue'
import AdditionalsServices from './additionalsServices'

export default {

  components: {
    AddNew,
    Edit,
    // EditSpeaker,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BBadge,
    // BDropdown,
    // BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isEditSidebarActive = ref(false)
    return {
      isAddNewSidebarActive,
      isEditSidebarActive,
    }
  },
  data() {
    return {
      items: [],
      tableColumns: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        {
          key: 'title',
          label: 'Título',
          sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        {
          key: 'actions',
          label: 'Ações',
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
      ],
      rows: 0,
      perPage: 10,
      currentPage: 1,
      nextPage: this.currentPage + 1,
      prevPage: this.currentPage - 1,
      sortBy: 'id',
      sortDesc: true,
      events: [],
      perPageOptions: [10, 25, 50, 100, 200, 300, 400, 500],
      selectValue: '',
      searchValue: '',
      isBusy: false,
      isRemoving: false,
      rowData: {}
    }
  },
  computed: {

  },
  watch: {

  },
  beforeMount() {
    AdditionalsServices().getAdditionals({ lesson_id: this.$route.params.lesson_id }).then(api => {
      this.items = api.data.response.additionals
    })
  },
  methods: {
    refresh() {
      AdditionalsServices().getAdditionals({ lesson_id: this.$route.params.lesson_id }).then(api => {
        this.items = api.data.response.additionals
      })
    },
    edit(data) {
      this.rowData.id = data.id
      this.rowData.title = data.title
      this.rowData.link = data.link
      console.log(typeof this.rowData.link)
      this.isEditSidebarActive = true
    },
    del(id) {
      this.$swal({
        title: 'Deletar material?',
        text: 'Você não será capaz de reverte essa ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b65f0',
        cancelButtonColor: '#c1c1c1',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim, desejo deletar!',
        showLoaderOnConfirm: true,
        preConfirm: () => AdditionalsServices().del({ id }).then(api => {
          if (api.data.status === 200) {
            this.refresh()
            this.$swal.fire(
              'Deletado!',
              'O material  foi apagado.',
              'success'
            )
          }
        }).catch(err => {
          this.$swal.fire(
            'Ops!',
            'Erro ao tentar deletar.',
            'error'
          )
        })
          .finally(() => {
            this.isBusy = false
          }),
        allowOutsideClick: () => !this.$swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal.fire(
            'Deletado!',
            'Congresso deletado com sucesso.',
            'success'
          )
        }
      })
    },
  },
}
</script>

<style lang="scss">
</style>
